<template>
    <div class="recovery-password">
        <router-link class="recovery-password__back" to="/">
            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.376 15.125H12.9397L17.4772 10.6013C17.7361 10.3423 17.8816 9.99118 17.8816 9.62501C17.8816 9.25885 17.7361 8.90768 17.4772 8.64876C17.2183 8.38984 16.8671 8.24438 16.501 8.24438C16.1348 8.24438 15.7836 8.38984 15.5247 8.64876L8.64971 15.5238C8.52453 15.6545 8.4264 15.8087 8.36096 15.9775C8.22343 16.3123 8.22343 16.6878 8.36096 17.0225C8.4264 17.1913 8.52453 17.3455 8.64971 17.4763L15.5247 24.3513C15.6525 24.4801 15.8046 24.5824 15.9722 24.6522C16.1397 24.722 16.3194 24.758 16.501 24.758C16.6825 24.758 16.8622 24.722 17.0298 24.6522C17.1973 24.5824 17.3494 24.4801 17.4772 24.3513C17.6061 24.2234 17.7084 24.0714 17.7782 23.9038C17.848 23.7362 17.8839 23.5565 17.8839 23.375C17.8839 23.1935 17.848 23.0138 17.7782 22.8462C17.7084 22.6787 17.6061 22.5266 17.4772 22.3988L12.9397 17.875H23.376C23.7406 17.875 24.0904 17.7301 24.3482 17.4723C24.6061 17.2144 24.751 16.8647 24.751 16.5C24.751 16.1353 24.6061 15.7856 24.3482 15.5277C24.0904 15.2699 23.7406 15.125 23.376 15.125Z" fill="#202225"/>
            </svg>
        </router-link>
        <div class="recovery-password__content">
            <router-link to="/">
                <img
                    src="@/assets/images/logos/accounts-logo.svg"
                    class="recovery-password__logo img-fluid"
                    alt="Tendencys"
                />
            </router-link>
            <recovery-password-form />
        </div>
    </div>
</template>

<script>
import RecoveryPasswordForm from '@/components/forms/RecoveryPassword.vue';

export default {
    name: 'recovery-password',
    components: {
        RecoveryPasswordForm,
    },
};
</script>

<style lang="scss">
.recovery-password {
    text-align: center;
    position: relative;

    &__back {
        position: absolute;
        left: 0;
        top: 0;
    }

    &__content {
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
    }

    &__logo {
        margin-bottom: 35px;
    }

    &__title {
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 30px;
    }

    &__text {
        color: #787F84;
        font-size: 16px;
        margin-bottom: 30px;
        font-weight: 400;

        b {
            font-weight: 600;
        }
    }

    &__image {
        height: auto;
        margin-bottom: 30px;
        max-width: 200px;
        width: 100%;
    }
}
</style>
