<template>
    <div class="recovery-password-form">
        <div v-if="error">
            <div class="alert alert-danger" role="alert">
                {{ $t('Request expired') }}
            </div>
        </div>
        <form @submit.prevent="updatePassword()">
            <h1 class="recovery-password__title">
                {{ $t('Recovery password') }}
            </h1>
            <p class="recovery-password__text">
                <b>{{ $t('Enter your new password') }}.</b>
            </p>
            <img
                src="@/assets/images/auth/mobile-notification.svg"
                class="recovery-password__image"
                alt="Tendencys"
            />
            <div class="text-left">
                <div class="recovery-password-form__selected-email-container mb-2">
                    <p class="recovery-password-form__selected-email">
                        <span class="recovery-password-form__email-icon fas fa-check-circle"></span>
                        {{ user.email }}
                    </p>
                </div>
                <div class="mb-2">
                    <ui-input
                        id="password"
                        v-model="formData.password"
                        name="password"
                        type="password"
                        placeholder="********"
                        :label="$t('Password')"
                        :field="v$.formData.password"
                    />
                </div>
                <div class="mb-3">
                    <ui-input
                        id="passwordRepeat"
                        v-model="formData.password_confirmation"
                        name="passwordRepeat"
                        type="password"
                        placeholder="********"
                        :label="$t('Confirm password')"
                        :field="v$.formData.password_confirmation"
                    />
                </div>
            </div>
            <ui-button
                :loading="loading"
                class="w-100"
                type="submit"
                variant="primary"
            >
                {{ $t('Update') }}
            </ui-button>
        </form>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { helpers, maxLength, minLength, required, sameAs } from '@vuelidate/validators';
import { decrypt } from '@tendencys/encrypter';

import UiButton from '@/components/ui/buttons/Button.vue';
import UiInput from '@/components/ui/inputs/Input.vue';


export default {
    name: 'RecoveryPassword',
    components: {
        UiButton,
        UiInput,
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            loading: false,
            error: null,
            user: {
                email: null,
                redirectUrl: null,
                siteId: null,
            },
            formData: {
                password: '',
                password_confirmation: '',
            },
            show: {
                password: false,
                repeatPassword: false,
            },
            tokenData: null,
        };
    },
    validations() {
        return {
            formData: {
                password: {
                    required: helpers.withMessage(this.$t('validations.required.female', { field: this.$t('password') }), required),
                    minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(8)),
                    maxLength: helpers.withMessage(({ $params }) => this.$t('This field must have a maximum of {max} characters', $params ), maxLength(25)),
                    valid: helpers.withMessage(this.$t('Password must include upper and lower case letters, numbers, and special characters'), value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[[\]@$!%*¡¿?&#=|.(){}:;<>,/~_+-])([A-Za-z\d[\]@$!%*¡¿?&#=|.(){}:;<>,/~_+-]|[^ ]){8,25}$/.test(value)),
                    $autoDirty: true,
                },
                password_confirmation: {
                    required: helpers.withMessage(this.$t('validations.required.female', { field: this.$t('password confirmation') }), required),
                    minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(8)),
                    maxLength: helpers.withMessage(({ $params }) => this.$t('This field must have a maximum of {max} characters', $params ), maxLength(25)),
                    sameAs: helpers.withMessage(this.$t('Passwords do not match'), sameAs(this.formData.password)),
                    $autoDirty: true,
                },
            },
        };
    },
    async mounted() {
        if (this.$route.query.site_id) {
            this.user.siteId = this.$route.query.site_id;
        }

        if (this.$route.query.redirect_url) {
            this.user.redirectUrl = this.$route.query.redirect_url;
        }
        
        const { _v } = this.$route.query;
        if (!_v) {
            this.error = 'The autorization is required.';
        } else {
            this.tokenData = JSON.parse(decrypt(_v, process.env.VUE_APP_TENCRYPT));
            this.axiosAccount.defaults.headers.common['Authorization'] = this.tokenData.token;
            await this.axiosAccount.get(`/api/accounts/recovery`)
            .then((res) => {
                this.user.email = res.data.email;
            }).catch((err) => {
                this.error = err.response.data.message;
            })
        }

    },
    methods: {
        async updatePassword() {
            try {
                this.v$.$touch();
    
                if (this.v$.$invalid) {
                    return;
                }

                this.loading = true;
                
                await this.axiosAccount.put('/api/accounts/recovery', { ...this.formData });
                const url = new URL(this.tokenData.redirect_url);
                const params = url.searchParams;
                const paramEmail = params.get('email');
                window.location.replace(`${process.env.VUE_APP_HOSTNAME}/login?site_id=${this.tokenData.site_id}${!paramEmail ? `&email=${this.user.email}`: ''}&redirect_url=${this.tokenData.redirect_url}&recovery_password=true`);
            } catch (error) {
                const status = error?.response?.status;

                if (status === 422) {
                    this.$refs.recoveryPasswordForm.setErrors({
                        password: [this.$t('The password must not be the same to earlier')]
                    });
                    return;
                }

                this.showError(error);
            } finally {
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            }
        },
    },
};
</script>

<style lang="scss">
.recovery-password-form {
    &__selected-email-container {
        background-color: #F2F6F8;
        border: 2px solid #F2F6F8;
        border-radius: 10px;
        padding: 13px 30px;
        position: relative;
        text-align: center;
    }
    
    &__selected-email {
        color: #5E5E5E;
        font-size: 16px;
        line-height: 1 !important;
        margin-bottom: 0;
    }

    &__email-icon {
        color: #6DBB1A;
    }

    &__send-again {
        color: #9093A1;
        font-size: 16px;
        font-weight: 400;
    }

    &__link {
        color: #004C74 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
    }
}
</style>
